import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'

const Wrapper = styled.section`
  width: 100%;
  background-image: url(/images/official/feature/bgFeature05.png);
  background-size: contain;
  background-repeat: repeat;
  background-position-y: center;
  background-color: #fbfbfb;
  .p-section-inner {
    max-width: 1366px;
    padding: 80px 0;
    margin: 0 auto;
    position: relative;
    .p-section-feature {
      width: 100%;
      margin: 0 auto;
      position: relative;
      ul {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-around;
        .txt-wrap {
          width: 340px;
          min-width: 340px;
          height: 378px;
          white-space: pre-wrap;
          background-image: url(/images/official/feature/bgFeature04.png);
          background-size: contain;
          background-position: bottom;
          text-align: center;
          margin: 0 20px;
          .label-txt {
            margin: 20px 0 30px;
          }
          p {
            margin: 0 15px;
            font-weight: 600;
            line-height: 1.6;
          }
        }
      }
    }
  }
  ${BreakPoints.largest} {
  }
  ${BreakPoints.medium} {
  }
`

/**
 * サービスサイト下層特徴4
 * @constructor
 */
const OfficialLowFeature02 = () => (
  <Wrapper>
    <div className="p-section-inner">
      <p className="foot-copy txt-center txt-size-26 txt-bold anim-fade-up mb-040">
        「効率が上がった」「楽になった」等の声が寄せられています
      </p>
      <div className="p-section-feature">
        <ul>
          <li className="txt-wrap anim-fade-up">
            <h5 className="label-txt txt-size-26 txt-bold-green">
              データ管理工数激減！
            </h5>
            <p>
              毎月提出される勤務表の数が膨大で
              <br />
              保存するのも一苦労だったのが
              <br />
              キンクラを利用することで
              <br />
              ファイル保存の作業が無くなりました。
              <br />
              <br />
              勝手に個人ごとに分けて管理されるので
              <br />
              勤務データを確認したい時も
              <br />
              欲しい情報がすぐに見つけれられます。
            </p>
          </li>
          <li className="txt-wrap anim-fade-up">
            <h5 className="label-txt txt-size-26 txt-bold-green">
              アラートで事前察知
            </h5>
            <p>
              36協定の警告基準に達した段階で
              <br />
              アラートが通知されるので
              <br />
              残業時間が増えている社員を
              <br />
              日々一人ひとりチェックする
              <br />
              必要がなくなりました。
              <br />
              <br />
              6ヵ月平均の残業時間など
              <br />
              見落としがちだった勤務状況のアラートも
              <br />
              設定できるので助かっています。
            </p>
          </li>
          <li className="txt-wrap anim-fade-up">
            <h5 className="label-txt txt-size-26 txt-bold-green">
              人為的ミス軽減
            </h5>
            <p>
              給与計算のたびに手作業で <br />
              勤務表からコピぺしていたのが <br />
              エクスポートしたCSVを使えば <br />
              一発で給与ソフトに入力されるので <br />
              数字の打ち間違いがなくなりました <br />
              <br />
              提出される勤務表は打刻情報をもとに <br />
              自動で生成されるので <br />
              従業員の記入ミスも減りました。
            </p>
          </li>
        </ul>
      </div>
    </div>
  </Wrapper>
)

export default OfficialLowFeature02
