import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import OfficialCommonH3Set from '../molecules/officialCommonH3Set'

const Wrapper = styled.section`
  width: 100%;
  background-image: url(/images/official/feature/bgSecurity01.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  .p-section-inner {
    max-width: 1080px;
    padding: 80px 0;
    margin: 0 auto;
    position: relative;
    .p-section-feature {
      width: 100%;
      padding: 60px;
      background: rgba(255, 255, 255, 0.85);
      border-radius: 10px;
      margin: 0 auto;
      position: relative;
      .security-area {
        display: inline-block;
        width: 50%;
        overflow: hidden;
        .img-wrap {
          float: left;
          img {
            width: 150px;
            margin-right: 15px;
          }
        }
        .txt-wrap {
          font-weight: 600;
          p {
            margin-bottom: 25px;
            color: #2074d6;
          }
        }
        &.part-01 {
          padding: 10px 20px 50px 5px;
          border-right: solid 1px #dcdee1;
          border-bottom: solid 1px #dcdee1;
        }
        &.part-02 {
          padding: 10px 5px 50px 40px;
          border-bottom: solid 1px #dcdee1;
        }
        &.part-03 {
          padding: 50px 20px 10px 5px;
          border-right: solid 1px #dcdee1;
        }
        &.part-04 {
          padding: 50px 5px 10px 40px;
        }
      }
    }
  }
  ${BreakPoints.xxLarge} {
    .p-section-inner {
      width: 96vw;
      .p-section-feature {
        padding: 20px;
        .security-area {
          display: inline-flex;
          flex-wrap: wrap;
          justify-content: center;
          text-align: center;
          padding: 20px !important;
          height: 330px;
          .img-wrap {
            float: unset;
            width: 100%;
            img {
              margin-right: 0;
            }
          }
          .txt-wrap {
            width: 100%;
            p {
            }
          }
          &.part-01 {
          }
          &.part-02 {
          }
          &.part-03 {
          }
          &.part-04 {
          }
        }
      }
    }
  }
  ${BreakPoints.medium} {
    .p-section-inner {
      padding: 60px 0 30px;
      .p-section-feature {
        padding-bottom: 0;
        .security-area {
          display: flex;
          width: 100%;
          border: none !important;
          border-bottom: solid 1px #dcdee1 !important;
          .img-wrap {
            img {
            }
          }
          .txt-wrap {
            p {
            }
          }
          &.part-01 {
          }
          &.part-02 {
          }
          &.part-03 {
          }
          &.part-04 {
          }
          &:last-child {
            border: none !important;
          }
        }
      }
    }
  }
`

/**
 * サービスサイト下層特徴3
 * セキュリティ
 * @constructor
 */
const OfficialLowFeature03 = () => (
  <Wrapper>
    <div className="p-section-inner">
      <OfficialCommonH3Set
        label="安心のセキュリティ"
        description="お預かりしているデータの安全性を確保する万全なセキュリティ体制を整えています"
        className="anim-fade-up"
      />
      <div className="p-section-feature anim-fade-up">
        <div className="security-area part-01">
          <div className="img-wrap">
            <img
              src="/images/official/feature/iconSecurity02.png"
              alt="情報資産の管理"
            />
          </div>
          <div className="txt-wrap">
            <p className="txt-size-26">情報資産の管理</p>
            重要な情報は暗号化して保存。
            <br />
            万一障害発生時もデータは
            <br />
            バックアップされているので安心です。
          </div>
        </div>
        <div className="security-area part-02">
          <div className="img-wrap">
            <img
              src="/images/official/feature/iconSecurity01.png"
              alt="通信の暗号化"
            />
          </div>
          <div className="txt-wrap">
            <p className="txt-size-26">通信の暗号化</p>
            ネットワーク通信はSSLで暗号化。
            <br />
            盗聴・漏えい等の脅威に対し
            <br />
            データの機密性を確保しています。
          </div>
        </div>
        <div className="security-area part-03">
          <div className="img-wrap">
            <img
              src="/images/official/feature/iconSecurity03.png"
              alt="ISMS認証"
            />
          </div>
          <div className="txt-wrap">
            <p className="txt-size-26">ISMS認証</p>
            ISMS認証（ISO27001）と <br />
            プライバシーマークを取得した <br />
            企業がキンクラを開発・運営
          </div>
        </div>
        <div className="security-area part-04">
          <div className="img-wrap">
            <img
              src="/images/official/feature/iconSecurity04.png"
              alt="脆弱性診断クリア"
            />
          </div>
          <div className="txt-wrap">
            <p className="txt-size-26">脆弱性診断クリア</p>
            キンクラは第三者企業による <br />
            脆弱性診断をクリアしています。
          </div>
        </div>
      </div>
    </div>
  </Wrapper>
)

export default OfficialLowFeature03
