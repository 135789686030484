import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'

const Wrapper = styled.section`
  width: 100%;
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%20%3Cdefs%3E%20%3Cstyle%3E%20.cls-1%2C%20.cls-3%20%7B%20fill%3A%20none%3B%20%7D%20.cls-1%20%7B%20stroke%3A%20%230000000a%3B%20%7D%20.cls-2%20%7B%20stroke%3A%20none%3B%20%7D%20%3C%2Fstyle%3E%20%3C%2Fdefs%3E%20%3Cg%20id%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2_23613%22%20data-name%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2%2023613%22%20class%3D%22cls-1%22%3E%20%3Crect%20class%3D%22cls-2%22%20width%3D%2212%22%20height%3D%2212%22%2F%3E%20%3Crect%20class%3D%22cls-3%22%20x%3D%220.5%22%20y%3D%220.5%22%20width%3D%2211%22%20height%3D%2211%22%2F%3E%20%3C%2Fg%3E%3C%2Fsvg%3E');
  background-repeat: repeat;
  .p-section-inner {
    max-width: 1200px;
    padding: 100px 0 30px;
    margin: 0 auto;
    position: relative;
    .p-section-feature {
      display: flex;
      width: 100%;
      max-width: 840px;
      margin: 0 auto 70px;
      padding: 45px 45px 40px;
      background: #d7f2ed;
      position: relative;
      border-radius: 10px;
      border: solid 3px #748681;
      box-shadow: 5px 5px 0 #ffee81;
      .img-wrap {
        position: absolute;
        bottom: 0;
      }
      .txt-wrap {
        width: min-content;
        text-align: center;
        .label-txt {
          width: 468px;
          height: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #ffffff;
          margin: 0 0 30px;
        }
        .detail-txt {
          font-weight: 600;
          opacity: 0.9;
          line-height: 1.7;
        }
      }
      &.strong01 {
        margin-left: 0;
        .img-wrap {
          position: absolute;
          width: 240px;
          left: 40px;
        }
        .txt-wrap {
          margin: 0 0 0 auto;
        }
      }
      &.strong02 {
        .img-wrap {
          position: absolute;
          width: 240px;
          right: 30px;
        }
        .txt-wrap {
          margin: 0 auto 0 0;
        }
      }
      &.strong03 {
        margin-right: 0;
        .img-wrap {
          position: absolute;
          width: 320px;
          left: 30px;
          bottom: 30px;
        }
        .txt-wrap {
          margin: 0 0 0 auto;
          width: 468px;
          .label-txt {
            width: 388px;
            height: 60px;
            margin-left: auto;
            margin-right: 30px;
          }
        }
      }
    }
    .foot-copy {
      margin-top: 80px;
    }
  }
  ${BreakPoints.large} {
    .p-section-inner {
      max-width: calc(100vw - 40px);
      margin: 0 auto;
      &:after {
      }
      .p-section-feature {
        width: calc(100vw - 40px);
        padding: 20px 10px;
        .img-wrap {
        }
        .txt-wrap {
          .label-txt {
          }
        }
        &.strong01 {
          margin: 0 auto 40px;
          .img-wrap {
            width: 26vw;
            left: 2vw;
          }
          .txt-wrap {
          }
        }
        &.strong02 {
          margin: 0 auto 40px;
          .img-wrap {
            width: 27vw;
            right: 2vw;
          }
          .txt-wrap {
            margin: 0 auto 0 0;
          }
        }
        &.strong03 {
          margin: 0 auto 40px;
          .img-wrap {
            width: 32vw;
            bottom: 15vw;
            left: 1vw;
          }
          .txt-wrap {
            .label-txt {
            }
          }
        }
      }
      .foot-copy {
      }
    }
  }
  ${BreakPoints.medium} {
    .p-section-inner {
      padding: 60px 0;
      .p-section-feature {
        width: calc(100vw - 40px);
        padding: 20px 10px;
        .img-wrap {
          z-index: 3;
        }
        .txt-wrap {
          position: relative;
          z-index: 2;
          margin: 0 auto;
          width: 100%;
          .label-txt {
            margin: 0 auto 15px;
            width: 100%;
            border-radius: 5px;
            font-size: 1.325em;
          }
        }
        &.strong01 {
          .img-wrap {
            width: 90px;
            bottom: 60%;
            left: 16px;
          }
          .txt-wrap {
          }
        }
        &.strong02 {
          .img-wrap {
            width: 90px;
            right: 10px;
            bottom: 60%;
          }
          .txt-wrap {
          }
        }
        &.strong03 {
          .img-wrap {
            width: 90px;
            bottom: 60%;
            left: 16px;
          }
          .txt-wrap {
            width: 100%;
            .label-txt {
              width: 100%;
            }
          }
        }
      }
      .foot-copy {
        margin-top: 60px;
      }
    }
  }
  ${BreakPoints.small} {
    .p-section-inner {
      padding-bottom: 5px;
      .p-section-feature {
        .img-wrap {
        }
        .txt-wrap {
          br {
            display: none;
          }
          .label-txt {
          }
        }
        &.strong01 {
          .img-wrap {
            display: none;
          }
          .txt-wrap {
            .label-txt {
            }
          }
        }
        &.strong02 {
          .img-wrap {
            display: none;
          }
          .txt-wrap {
            .label-txt {
            }
          }
        }
        &.strong03 {
          .img-wrap {
            display: none;
          }
          .txt-wrap {
            .label-txt {
            }
          }
        }
      }
    }
  }
`

/**
 * サービスサイト下層特徴1
 * @constructor
 */
const OfficialLowFeature01 = () => (
  <Wrapper>
    <div className="p-section-inner">
      {/** 特徴1 */}
      <div className="p-section-feature strong01 fade-in-auto">
        <img
          src="/images/official/feature/imgLowFeature02.png"
          alt="キンクラはすぐに導入&amp;利用開始できる！"
          className="img-wrap"
          placeholder="none"
        />
        <div className="txt-wrap">
          <h4 className="label-txt txt-size-26 txt-bold-orange">
            すぐに導入&amp;利用開始できる！
          </h4>
          <div className="detail-txt">
            キンクラの導入には初期費用ゼロ、専用機器も不要。
            <br />
            たったの1分でスマホとPCからいつでもアクセス可能な
            <br />
            貴社だけのクラウド型勤怠管理システムの利用を開始できます。
          </div>
        </div>
      </div>

      {/** 特徴2 */}
      <div className="p-section-feature strong02 fade-in-auto">
        <img
          src="/images/official/feature/imgLowFeature03.png"
          alt="キンクラはリアルタイムに情報を把握"
          className="img-wrap"
          placeholder="none"
        />
        <div className="txt-wrap">
          <h4 className="label-txt txt-size-26 txt-bold-orange">
            リアルタイムに情報を把握
          </h4>
          <div className="detail-txt">
            出社・常駐先・リモートワークなど当日の勤務地は <br />
            打刻する際に登録されるため、社員の勤務状況を <br />
            キンクラを通じてリアルタイムに確認できます。
          </div>
        </div>
      </div>

      {/** 特徴3 */}
      <div className="p-section-feature strong03 anim-fade-up is-show">
        <img
          src="/images/official/feature/imgLowFeature04.png"
          alt="キンクラで詳細な承認ルール設定"
          className="img-wrap"
          placeholder="none"
        />
        <div className="txt-wrap">
          <h4 className="label-txt txt-size-26 txt-bold-orange">
            詳細な承認ルール設定
          </h4>
          <div className="detail-txt">
            申請の種類ごとに最大5段階の承認ルールを設定可能です。
            <br />
            承認ルールはグループ単位で設定できるから
            <br />
            部署によって複雑でバラバラなワークフローでも大丈夫。
            <br />
            承認画面ではどの段階で誰が対応中なのかも確認できます。
          </div>
        </div>
      </div>
    </div>
  </Wrapper>
)

export default OfficialLowFeature01
