import styled from '@emotion/styled'
import { graphql, PageProps } from 'gatsby'
import React from 'react'
import SEO from '../components/atoms/seo'
import OfficialGalleryBtnSet from '../components/molecules/officialGalleryBtnSet'
import OfficialLayout from '../components/organisms/officialLayout'
import OfficialLowFeature01 from '../components/organisms/officialLowFeature01'
import OfficialLowFeature02 from '../components/organisms/officialLowFeature02'
import OfficialLowFeature03 from '../components/organisms/officialLowFeature03'
import OfficialLowHeader from '../components/organisms/officialLowHeader'
import OfficialNewFunctionItem from '../components/organisms/officialNewFunctionItem'

const Wrapper = styled.main`
  .no-arrow {
    margin-bottom: 0 !important;

    &:after {
      display: none !important;
    }
  }
`

type IProps = PageProps<GatsbyTypes.IndexPageQuery>

/**
 * 特徴ページ[サービスサイト特徴]
 * @constructor
 */
const FeaturePage = ({ location }: IProps) => (
  <OfficialLayout location={location} className="p-page-feature">
    <SEO title="特徴" />
    <Wrapper>
      <OfficialLowHeader
        label="特徴"
        description="クラウド型勤怠管理システムのキンクラだからおすすめできることがあります。"
      />
      <OfficialLowFeature01 />
      <OfficialLowFeature02 />
      <OfficialGalleryBtnSet />
      <OfficialLowFeature03 />
      <OfficialNewFunctionItem className="no-arrow" />
    </Wrapper>
  </OfficialLayout>
)

export default FeaturePage

export const pageQuery = graphql`
  query FeaturePage {
    site {
      siteMetadata {
        title
      }
    }
  }
`
